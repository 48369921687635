import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { LocalStorageDal } from "../LocalStorageDal";
import * as VcaTypes from "../VcaTypes"


interface INavMenuState {
    collapsed: boolean;
    loginDetails: VcaTypes.IDriverDetails | null;
}

export class NavMenu extends Component<any, INavMenuState> {
    static displayName = NavMenu.name;

    loginStatusChanged = (loginDetails?: VcaTypes.IDriverDetails | null) => {
        this.setState({
            loginDetails: loginDetails || null
        });
    };

    constructor(props: any) {
        super(props);

        var loginDetails = LocalStorageDal.getDriverDetails();
        LocalStorageDal.loginStatusChanged.on(this.loginStatusChanged);

        this.state = {
            collapsed: true,
            loginDetails: loginDetails
        };
    }

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handleLogout = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        LocalStorageDal.clearLogin();
    };

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/"><img src="/tin-logo.png" className="d-inline-block align-top" width="30" height="30" alt="Trackitnow logo"/>Trackitnow Vehicle Check</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/insurance">Breakdown & Insurance</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/about">About</NavLink>
                                </NavItem>
                                {this.state.loginDetails && <li className="nav-item">
                                    <button onClick={this.handleLogout} className="btn btn-link nav-link text-dark">Logout {this.state.loginDetails.userName}@{this.state.loginDetails.clientCode}</button>
                                </li>
                                }
                                {!this.state.loginDetails &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/login">Login</NavLink>
                                    </NavItem>
                                }
                               
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
