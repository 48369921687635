import React, { Component } from "react";

interface ILoginControlProps {
    isValid: boolean;
    value: string;
    inputName: string;
    changeState(inputName: string, value: string): void;
    fieldLabel: string;
    inputType?: "text" | "password" ;
}

export class LoginControl extends Component<ILoginControlProps, any> {

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.changeState(this.props.inputName, event.target.value);
    }

    render() {
        const getFormControlClassName = (isValid: boolean) => {
            return isValid ? "form-control" : "form-control is-invalid";
        };

        const inputType = this.props.inputType || "text";

        return (
            <div className="form-group">
                <label htmlFor="loginUserName">{this.props.fieldLabel}</label>
                <input
                    id={"login_" + this.props.inputName}
                    className={getFormControlClassName(this.props.isValid)}
                    type={inputType}
                    name={this.props.inputName}
                    value={this.props.value}
                    onChange={this.handleChange} />
                {!this.props.isValid && <div className="invalid-feedback">Please provide a {this.props.fieldLabel}.</div>}
            </div>
        );
    }
}