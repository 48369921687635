import React, { Component, createRef } from 'react';
import * as VcaTypes from "../VcaTypes"

interface IEditableVehicleTypePropertyState {
}

interface IEditableVehicleTypePropertyProps {
    vehicleTypes: VcaTypes.IEraObjectType[];
    vehicleTypesWithQuestions: VcaTypes.IEraObjectType[];
    isValid: boolean | null;
    inputName: string;
    fieldLabel: string;
    originalValue: number;
    editedValue: number;
    isChanged: boolean;
    focusInput: boolean;
    valueChanged: (isEdited: boolean, editedValue: number) => void;
}

export class EditableVehicleTypeProperty extends Component<IEditableVehicleTypePropertyProps, IEditableVehicleTypePropertyState> {
   
    private input = createRef<HTMLSelectElement>();

    componentDidUpdate() {
        if (this.props.focusInput) {
            const node = this.input.current;
            if (node) {
                node.focus();
            }
        }
    }

    handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.valueChanged(this.props.isChanged, parseInt(event.target.value));
    };

    handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.valueChanged(event.target.checked, this.props.editedValue);
    };

    render() {

        const inputId = `chosen-vehicle-${this.props.inputName}`;
        const allowChangeId = `${inputId}-allowChange`;
        const isInvalid = this.props.isChanged && this.props.isValid === false;
        const inputClassName = isInvalid
            ? "form-control is-invalid"
            : "form-control";

        const valueToUse = this.props.isChanged ? `${this.props.editedValue}` : `${this.props.originalValue}`;

        return (<div className="form-group">
            <label htmlFor={inputId}>{this.props.fieldLabel}</label>
            <div className="input-group">
                <select disabled={!this.props.isChanged} className={inputClassName}  value={valueToUse} onChange={this.handleSelectChange}>
                    {
                        this.props.vehicleTypes.map(vt => <option key={vt.objectTypeId} value={vt.objectTypeId}>{vt.objectTypeName}</option>)
                    }
                </select>
                <div className="input-group-append">
                    <div className="input-group-text"><input type="checkbox" id={allowChangeId
                    } className="form-check-input ml-0" defaultChecked={this.props.isChanged} onChange={this.handleCheckChange} /><label className="form-check-label ml-3" htmlFor={allowChangeId
                    }> Update</label></div></div>
            </div>
                    {isInvalid && <div className="invalid-feedback">Please select a vehicle type with questions ({this.props.vehicleTypesWithQuestions.map(vt => vt.objectTypeName).join(", ")}).</div>}
        </div>);
    }

}