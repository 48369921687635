import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Login } from './components/Login';
import { CheckList } from './components/CheckList';
import { StartCheck } from "./components/StartCheck";
import { SignCheck } from "./components/SignCheck";
import { CheckComplete } from "./components/CheckComplete";
import { About } from "./components/About";
import { Insurance } from "./components/Insurance";
import { Sync } from "./Sync";

import './custom.css'


export default class App extends Component {
	
  static displayName = App.name;
  


   constructor(props:any) {
	    super(props);
	    Sync.initialiseTimedSync(1000 * 60 * 20);
   }

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/login/:first?' component={Login} />
        <Route path='/checklist/:key' component={CheckList} />
        <Route path='/sign/:key' component={SignCheck} />
        <Route path='/start-check' component={StartCheck} />
        <Route path='/check-complete' component={CheckComplete} />
        <Route path='/about' component={About} />
        <Route path='/insurance' component={Insurance} />
      </Layout>
    );
  }
}
