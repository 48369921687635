import React, { Component } from 'react';
import * as VcaTypes from "../VcaTypes";

interface IVehicleSelectorProps {
    availableVehicles: VcaTypes.IEraObject[];
    vehicleSelected: (eraObject: VcaTypes.IEraObject) => void;
}

interface IVehicleSelectorState {
    filter: string;
}

export class VehicleSelector extends Component<IVehicleSelectorProps, IVehicleSelectorState> {

    constructor(props: any) {
        super(props);

        this.state = {
            filter: ""
        };
    }

    handleSelectButtonClick = (vehicle: VcaTypes.IEraObject) => {
        this.props.vehicleSelected(vehicle);
    }

    handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({filter: event.target.value});
    }

    private stringIncludes(s: string | null, filterLowerCase: string) {
        return s !== null && s.toLowerCase().includes(filterLowerCase);
    }

    render() {

        var filter = this.state.filter.toLowerCase();

        const filteredElements = this.props.availableVehicles
            .filter(e => this.stringIncludes(e.registration, filter) || this.stringIncludes(e.number, filter))
            .map(e => <tr key={e.objectId}><td>{e.number}</td><td>{e.registration}</td><td>{e.makeModel}</td><td><button className="btn btn-primary" onClick={() => this.handleSelectButtonClick(e)}>Select</button></td></tr>);

        return (<div>
                    <div className="form-group">
                        <label htmlFor="choose-vehicle-search">Find your vehicle</label>
                        <input type="text" placeholder="Registration or name" id="choose-vehicle-search" className="form-control" value={this.state.filter} onChange={this.handleFilterChange} />
                    </div>
{filteredElements.length > 0 && <table className="table table-condensed"><thead><tr><th>Name</th><th>Registration</th><th>Vehicle</th><th></th></tr></thead><tbody>
                                    { filteredElements }
                </tbody></table>}
        </div>
        );
    }

}
