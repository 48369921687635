import React, { Component } from "react";
import * as VcaTypes from "../VcaTypes"
import { EditableVehicleProperty } from "./EditableVehicleProperty";
import { EditableVehicleTypeProperty } from "./EditableVehicleTypeProperty";

export interface ISelectedVehicleProps {
    vehicle: VcaTypes.IEraObject;
    vehicleTypes: VcaTypes.IEraObjectType[];
    vehicleTypesWithQuestions: VcaTypes.IEraObjectType[];
    checkStarted: (result: ISelectedVehicleResult) => void;
}

interface IUpdateableFieldState {
    isUpdated: boolean;
    updatedValue: string;
    shouldFocus: boolean;
    isValid: boolean | null;
    isMandatory: boolean;
}

interface ISelectedVehicleState {
    registration: IUpdateableFieldState;
    makeModel: IUpdateableFieldState;
    isValid: boolean;
    objectTypeId: number;
    objectTypeIdIsEdited: boolean;
    objectTypeIdIsValid: boolean | null;
    vcaQuestionProfileId: number | null;
    isSubmitting: boolean;
    errorMessage: string;
}

export interface ISelectedVehicleResult {
    vehicle: VcaTypes.IEraObject;
    modifiedRegistration: string | null;
    modifiedMakeModel: string | null;
    modifiedObjectTypeId: number | null;
    objectTypeId: number;
    odometerReadingKm?: number | null;
    vcaQuestionProfileId?: number | null;
}

export class SelectedVehicle extends Component<ISelectedVehicleProps, ISelectedVehicleState> {

    alertMessage: React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props);

        this.alertMessage = React.createRef<HTMLDivElement>();

        const defaultUpdateable: IUpdateableFieldState = {
            isUpdated: false,
            updatedValue: "",
            shouldFocus: false,
            isValid: null,
            isMandatory: false
        };

        this.state = {
            registration: { ...defaultUpdateable, isMandatory: !this.props.vehicle.registration },
            makeModel: { ...defaultUpdateable, isMandatory: this.makeModelShouldBeMandatory(props.vehicle.objectTypeId) },
            isValid: true,
            objectTypeId: props.vehicle.objectTypeId,
            objectTypeIdIsEdited: false,
            objectTypeIdIsValid: true,
            vcaQuestionProfileId: props.vehicle.vcaQuestionProfileId,
            isSubmitting: false,
            errorMessage: ""
        };
    }

    getUpdateableFields = (state: ISelectedVehicleState): IUpdateableFieldState[] => {
        return [state.registration, state.makeModel];
    };

    handleEditableVehiclePropertyValueChanged = (isEdited: boolean,
        editedValue: string | null,
        propertyName: keyof ISelectedVehicleState) => {

        var newState = { ...this.state } as ISelectedVehicleState;
        var propertyState = newState[propertyName] as IUpdateableFieldState;

        this.getUpdateableFields(newState).forEach(s => {
            s.shouldFocus = false;
        });

        const focusInput = !propertyState.isUpdated && isEdited;

        propertyState.shouldFocus = focusInput;
        propertyState.updatedValue = editedValue || "";
        propertyState.isUpdated = isEdited;

        if (propertyState.isValid !== null) {
            this.updatePropertyValid(propertyState);
        }

        this.setState(newState);
    };

    updatePropertyValid = (s: IUpdateableFieldState): boolean => {
        if (s.isUpdated || s.isMandatory) {
            s.isValid = !!s.updatedValue;
            return s.isValid;
        } else {
            s.isValid = null;
            return true;
        }
    };

    makeModelShouldBeMandatory(objectTypeId: number) {
        // If is a trailer or already have a makeModel then make/model is optional
        return objectTypeId !== 10 && !this.props.vehicle.makeModel;
    }

    updateMakeModelMandatory(state: ISelectedVehicleState) {
        const objectTypeId = state.objectTypeIdIsEdited ? state.objectTypeId : this.props.vehicle.objectTypeId;
        const mandatoryMakeModel = this.makeModelShouldBeMandatory(objectTypeId);
        
        if (mandatoryMakeModel !== state.makeModel.isMandatory) {
            state.makeModel.isMandatory = mandatoryMakeModel;
            this.updatePropertyValid(state.makeModel);
        }
    }

    updateObjectTypeIdPropertyValid(state: ISelectedVehicleState) {
        state.objectTypeIdIsValid = !!this.props.vehicleTypesWithQuestions.filter(vt => vt.objectTypeId === state.objectTypeId).length || !!this.state.vcaQuestionProfileId;
        this.updateMakeModelMandatory(state);
    }

    handleObjectTypeIdValueChanged = (isEdited: boolean, editedValue: number) => {
        var newState = { ...this.state } as ISelectedVehicleState;
        this.getUpdateableFields(newState).forEach(s => {
            s.shouldFocus = false;
        });
        newState.objectTypeId = editedValue;
        newState.objectTypeIdIsEdited = isEdited;
        if (newState.objectTypeIdIsValid !== null) {
            this.updateObjectTypeIdPropertyValid(newState);
        }

        this.setState(newState);
    };

    handleStartCheck = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        var newState = { ...this.state } as ISelectedVehicleState;

        var isValid = true;
        this.getUpdateableFields(newState).forEach(s => {
            if (!this.updatePropertyValid(s)) {
                isValid = false;
            }
        });

        const objectTypeId = newState.objectTypeIdIsEdited ? newState.objectTypeId : this.props.vehicle.objectTypeId;

        newState.isValid = isValid;;

        const validationMessages: string[] = [];

        if (!newState.isValid) {
            validationMessages.push("Please enter all fields.");
        }

        this.updateObjectTypeIdPropertyValid(newState);

        if (!newState.objectTypeIdIsValid) {
            newState.isValid = false;
            validationMessages.push("Please select a Vehicle type that has available questions.");
        }

        newState.errorMessage = validationMessages.length ? validationMessages.join(" ") : "";

        newState.isSubmitting = newState.isValid;

        this.setState(newState);

        if (newState.isSubmitting) {

            const getUpdateableValue = (fld: IUpdateableFieldState) => {
                if (fld.isMandatory || fld.isUpdated) {
                    return fld.updatedValue;
                }
                return null;
            };

            const result: ISelectedVehicleResult = {
                odometerReadingKm: null,
                modifiedMakeModel: getUpdateableValue(newState.makeModel),
                modifiedObjectTypeId: null,
                modifiedRegistration: getUpdateableValue(newState.registration),
                vehicle: this.props.vehicle,
                objectTypeId: objectTypeId,
                vcaQuestionProfileId: newState.vcaQuestionProfileId,
            };

            if (newState.objectTypeIdIsEdited) {
                result.modifiedObjectTypeId = newState.objectTypeId;
            }

            this.props.checkStarted(result);
        }
    };

    render() {
        const submitButtonClassName = this.state.isSubmitting ? "btn btn-primary is-submitting" : "btn btn-primary";
        const submitButtonDisabled = this.state.isSubmitting ? true : false;

        return (<div>
            <h2>{this.props.vehicle.number}</h2>
            <EditableVehicleProperty inputName="registration" fieldLabel="Registration" originalValue={this.props
                .vehicle.registration ||
                ""}
                editedValue={this.state.registration.updatedValue} isChanged={this.state.registration.isUpdated
                } focusInput={this.state.registration.shouldFocus}
                isMandatory={this.state.registration.isMandatory} isValueValid={this.state.registration.isValid}
                valueChanged={(u, v) => { this.handleEditableVehiclePropertyValueChanged(u, v, "registration") }} />
            <EditableVehicleProperty inputName="makeModel" fieldLabel="Make / model" originalValue={this.props.vehicle
                .makeModel ||
                ""}
                editedValue={this.state.makeModel.updatedValue} isChanged={this.state.makeModel.isUpdated} focusInput={
                    this.state.makeModel.shouldFocus}
                isMandatory={this.state.makeModel.isMandatory} isValueValid={this.state.makeModel.isValid}
                valueChanged={(u, v) => { this.handleEditableVehiclePropertyValueChanged(u, v, "makeModel") }} />
            <EditableVehicleTypeProperty isValid={this.state.objectTypeIdIsValid} vehicleTypes={this.props.vehicleTypes
            } vehicleTypesWithQuestions={this.props.vehicleTypesWithQuestions}
                fieldLabel="Vehicle type" inputName="vehicleType"
                editedValue={this.state.objectTypeId} isChanged={this.state.objectTypeIdIsEdited} focusInput={false
                } originalValue={this.props.vehicle.objectTypeId}
                valueChanged={this.handleObjectTypeIdValueChanged} />
            <div className="form-group">
                <button disabled={submitButtonDisabled} className={submitButtonClassName} onClick={this
                    .handleStartCheck}>Start check</button>
            </div>
            {!this.state.isValid &&
                <div ref={this.alertMessage} className="alert alert-danger">
                    {this.state.errorMessage}
                </div>
            }
        </div>
        );
    }

}