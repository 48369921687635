import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { LoginControl } from "./LoginControl";
import { LocalStorageDal } from "../LocalStorageDal";
import { AddToHomeControl } from "./AddToHomeControl";
import { History } from "history";
import { Sync } from "../Sync";

type LoginRouteParams = { first?: string }

interface ILoginProps extends RouteComponentProps<LoginRouteParams> {
    history: History;
}

interface ILoginState extends ILoginFormState {

    isValid: boolean;
    errorMessage: string;
    focusError: boolean;
    userNameValid: boolean;
    passwordValid: boolean;
    clientCodeValid: boolean;
    isSubmitting: boolean;
}

interface ILoginFormState {
    userName: string;
    password: string;
    clientCode: string;
}

export class Login extends Component<ILoginProps, ILoginState> {

    alertMessage: React.RefObject<HTMLDivElement>;
    private showAddToHomescreen: boolean = false;

    constructor(props: any) {
        super(props);
        
        this.alertMessage = React.createRef<HTMLDivElement>();
        
        if (this.props.match.params.first === "first") {
            this.showAddToHomescreen = true;
        }

        this.state = {
            userName: "",
            password: "",
            clientCode: "",
            isValid: true,
            errorMessage: "",
            userNameValid: true,
            passwordValid: true,
            clientCodeValid: true,
            isSubmitting: false,
            focusError: false
        }
    }

    applyIndividualValidation = (propertyName: keyof ILoginFormState, state: ILoginState) => {
        var propertyValidName = (propertyName + "Valid") as keyof ILoginState;
        var stateAny = state as any;
        stateAny[propertyValidName] = !!state[propertyName];
    };

    applyOverallStateValidation = (newState: ILoginState) => {

        newState.isValid = newState.userNameValid && newState.passwordValid && newState.clientCodeValid;
        if (!newState.isValid) {
            newState.errorMessage = "Please enter all fields.";
        }
        return newState.isValid;
    };


    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var newState = { ...this.state } as ILoginState;
        var propertyName = event.target.name as keyof ILoginFormState;
        newState[propertyName] = event.target.value;
        this.applyIndividualValidation(propertyName, newState);
        this.applyOverallStateValidation(newState);
        this.setState(newState);
    };

    handleControlChangeState = (inputName: string, value: string) => {
        var newState = { ...this.state } as ILoginState;
        var propertyName = inputName as keyof ILoginFormState;
        newState[propertyName] = value;
        this.applyIndividualValidation(propertyName, newState);
        this.applyOverallStateValidation(newState);
        this.setState(newState);
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        var newState = { ...this.state } as ILoginState;

        this.applyIndividualValidation("userName", newState);
        this.applyIndividualValidation("password", newState);
        this.applyIndividualValidation("clientCode", newState);

        var isValid = this.applyOverallStateValidation(newState);
        newState.focusError = !isValid;
        this.setState(newState);
        if (isValid) {
            this.submitLogin();
        }
    }

    componentDidUpdate() {
        if (this.state.focusError) {
            const node = this.alertMessage.current;
            if (node) {
                window.scrollTo(0, node.offsetTop);
            }
        }
    }

    render() {
        const submitButtonClassName = this.state.isSubmitting ? "btn btn-primary is-submitting" : "btn btn-primary";
        const submitButtonDisabled = this.state.isSubmitting ? true : false;

        return (
            <form onSubmit={this.handleSubmit}>
                <h1>Login</h1>

                <LoginControl inputName="userName" fieldLabel="User name" value={this.state.userName} isValid={this.state.userNameValid} changeState={this.handleControlChangeState} />
                <LoginControl inputType="password" inputName="password" fieldLabel="Password" value={this.state.password} isValid={this.state.passwordValid} changeState={this.handleControlChangeState} />
                <LoginControl inputName="clientCode" fieldLabel="Client code" value={this.state.clientCode} isValid={this.state.clientCodeValid} changeState={this.handleControlChangeState} />

                <div className="form-group">
                    <input type="submit" className={submitButtonClassName} disabled={submitButtonDisabled} />
                </div>
                {!this.state.isValid && <div ref={this.alertMessage} className="alert alert-danger">
                    {this.state.errorMessage}
                </div>
                }
                {this.showAddToHomescreen && <div><hr /><AddToHomeControl /></div>
                }
            </form>
        );
    }

    handleFetchErrors(response: any) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    submitLogin = () => {

        this.setState({ isSubmitting: true, isValid: true, errorMessage: "" });

        const data = { userName: this.state.userName, password: this.state.password, clientCode: this.state.clientCode };
        const jsonData = JSON.stringify(data);
        const url = "api/users/authenticate";
        fetch(url,
            {
                method: "POST",
                credentials: "same-origin",
                body: jsonData,
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Device-Uid": LocalStorageDal.ensureAndGetDeviceId()
                }
            })
            .then(response => Promise.all([response, response.json()]))
            .then(([response, body]) => {
                debugger;
                this.setState({ isSubmitting: false });
                if (response.ok) {
                    LocalStorageDal.storeLogin(body.token, { clientCode: body.clientCode, userName: body.userName });
                    Sync.trySyncNow();
                    this.props.history.push("/");
                } else {
                    if (body && body.message) {
                        throw body.message;
                    } else {
                        throw response.statusText;
                    }
                }
            }
            )
            .catch((error) => {
                debugger;
                this.setState({ isSubmitting: false, isValid: false, errorMessage: "" + error, password: "", focusError: true });
            });

    }


}


