import React, { Component } from "react";
import { LocalStorageDal } from "../LocalStorageDal";
import { History } from "history";
import { AddToHomeControl } from "./AddToHomeControl";
import Popup from "reactjs-popup";
import { Sync, SyncResponseStatus } from "../Sync";

interface IAboutProps {
    history: History;
}

interface IAboutState {
    isUpdatingApp: boolean;
    isCheckingForAppUpdate: boolean;
    appUpdateCheckFailed: boolean;
    latestAppVersionAvailable: string | null;
}

export class About extends Component<IAboutProps, IAboutState> {
    constructor(props: IAboutProps) {
        super(props);

        this.state = {
            isCheckingForAppUpdate: true,
            appUpdateCheckFailed: false,
            latestAppVersionAvailable: null,
            isUpdatingApp: false,
        };

        const url = "api/update";
        fetch(url,
            {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Device-Uid": LocalStorageDal.ensureAndGetDeviceId()
                }
            })
            .then(response => Promise.all([response, response.json()]))
            .then(([response, body]) => {
                if (response.ok) {
                    this.setState({ isCheckingForAppUpdate: false, appUpdateCheckFailed: false, latestAppVersionAvailable: body.appVersion });
                } else {
                    if (body && body.message) {
                        throw body.message;
                    } else {
                        throw response.statusText;
                    }
                }
            }
            )
            .catch(() => {
                this.setState({ isCheckingForAppUpdate: false, appUpdateCheckFailed: true });
            });

    }

    handleClearData = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        LocalStorageDal.clearAll();
        this.props.history.push("/login");
    }

    handleInstallUpdate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        this.setState({isUpdatingApp: true});
        caches.keys().then(names => {
            for (let name of names)
                caches.delete(name);
        });
        window.location.reload(true);
    }

    render() {

        const lastSyncTimeTicks = LocalStorageDal.getLastSyncTimeTicks();
        let lastSyncTime = "Not yet synced";
        if (lastSyncTimeTicks) {
            const date = new Date(lastSyncTimeTicks);
            lastSyncTime = date.toLocaleString();
        }

        const currentAppVersion = LocalStorageDal.getAppVersion();

        const questionsVersions = LocalStorageDal.getQuestionsVersions();

        const updateIsAvailable = this.state.latestAppVersionAvailable !== null && currentAppVersion !== this.state.latestAppVersionAvailable;

        const updateVersionMessage = this.state.latestAppVersionAvailable !== null
            ? this.state.latestAppVersionAvailable
            : "Version unavailable";

        const lastSyncStatus = Sync.lastSyncAttemptResult;
        var lastSyncStatusDescription = "";
        if (lastSyncStatus) {
	        switch (lastSyncStatus.status) {
	        case SyncResponseStatus.Unauthorised:
		        lastSyncStatusDescription = "Not logged in";
		        break;
	        case SyncResponseStatus.Error:
		        lastSyncStatusDescription = `Error: ${lastSyncStatus.message}`;
		        break;
	        }

	        if (lastSyncStatusDescription && Sync.lastSyncAttemptTicks) {
		        lastSyncStatusDescription = `${lastSyncStatusDescription} at ${new Date(Sync.lastSyncAttemptTicks).toLocaleString()}`;
	        }
        }
        return (
            <div>
                <div className="form-group">
                    <label>Last successful sync at</label>
                    <input className="form-control" readOnly value={lastSyncTime} />
                </div>
                
                {lastSyncStatusDescription && <div className="form-group">
                    <label>Last sync attempt</label>
                    <input className="form-control" readOnly value={lastSyncStatusDescription} />
                </div>}

                <div className="form-group">
                    <label>App version</label>
                    <input className="form-control" readOnly value={currentAppVersion} />
                </div>
                <div className="form-group">
                    <label>Latest app version</label>
                    {this.state.isCheckingForAppUpdate && <div className="text-info">Checking for update...</div>}
                    {!this.state.isCheckingForAppUpdate && <input className="form-control" readOnly value={updateVersionMessage} />}
                </div>
                {updateIsAvailable && <div className="form-group"><button disabled={this.state.isUpdatingApp} onClick={this.handleInstallUpdate} className="btn btn-primary">Install update</button> {this.state.isUpdatingApp && <span className="text-info">Updating...</span> }</div>}
                
                <div className="form-group">
                    <label>Questions version</label>
                    <input className="form-control" readOnly value={questionsVersions.questionsVersion || "No questions"} />
                </div>
                <div className="form-group">
                    <label>Profile Questions version</label>
                    <input className="form-control" readOnly value={questionsVersions.profileQuestionsVersion || "No profile questions"} />
                </div>
                <div className="form-group">
                    <label>Device uid</label>
                    <input className="form-control" readOnly value={LocalStorageDal.ensureAndGetDeviceId()} />
                </div>

                <Popup modal className="vca-popup-content"
                    trigger={<button className="btn btn-danger">Clear data</button>} closeOnDocumentClick={false}>
                    {(close: any) => (
                        <div className="modal fade show modal-show-always" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Clear data</h5>
                                        <button type="button" className="close" onClick={close} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Clearing data will delete any ongoing and unsynchronised checks from this device and log you out.</p>
                                        <p>Are you sure you want to proceed?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger" onClick={this.handleClearData}>Clear data</button>
                                        <button type="button" className="btn btn-secondary" onClick={close}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                </Popup>
                <hr/>
                <AddToHomeControl />
            </div>
        );
    }

}