import React, { Component } from "react";
import { LocalStorageDal } from "../LocalStorageDal";
import * as VcaTypes from "../VcaTypes";
import { VehicleSelector } from "./VehicleSelector";
import { SelectedVehicle, ISelectedVehicleResult } from "./SelectedVehicle";
import { History } from "history";

interface IStartCheckProps {
    history: History;
}

interface IStartCheckState {
    selectedVehicle: VcaTypes.IEraObject | null;
    availableVehicles: VcaTypes.IEraObject[];
    vehicleTypes: VcaTypes.IEraObjectType[];
    vehicleTypesWithQuestions: VcaTypes.IEraObjectType[];
    availableQuestions: VcaTypes.IQuestionsConfig | null;
    distanceUnits: string;
}

export class StartCheck extends Component<IStartCheckProps, IStartCheckState> {
    constructor(props: any) {
        super(props);

        const availableQuestions = LocalStorageDal.loadQuestionsConfig();
        const vehicleTypes = LocalStorageDal.loadEraObjectTypes() || [];

        const haveQuestionForObjectTypeId = (objectTypeId: number): boolean => {
            return (
                availableQuestions != null &&
                !!availableQuestions.vehicleTypeQuestions.filter(
                    q => q.objectTypeId === objectTypeId
                ).length
            );
        };

        const vehicleTypesWithQuestions = vehicleTypes.filter(vt =>
            haveQuestionForObjectTypeId(vt.objectTypeId)
        );

        this.state = {
            selectedVehicle: null,
            availableVehicles: LocalStorageDal.loadEraObjects() || [],
            vehicleTypes: vehicleTypes,
            vehicleTypesWithQuestions: vehicleTypesWithQuestions,
            distanceUnits: LocalStorageDal.loadDistanceUnits() || "miles",
            availableQuestions: availableQuestions,
        };
    }

    handleVehicleSelected = (vehicle: VcaTypes.IEraObject) => {
        this.scrollToTop();
        this.setState({ selectedVehicle: vehicle });
    };

    handleCheckStarted = (result: ISelectedVehicleResult) => {
        const questions: VcaTypes.IQuestionsConfig = this.state
            .availableQuestions as VcaTypes.IQuestionsConfig;
        const typeQuestions = result.vcaQuestionProfileId
            ? questions.profileQuestions?.find(q => q.profileId === result.vcaQuestionProfileId)
            : questions.vehicleTypeQuestions.find(q => q.objectTypeId === result.objectTypeId);
        if (!typeQuestions) {
            throw new Error("Unable to find Type Questions");
        }
        const checkSections: VcaTypes.ISavedCheckSection[] = typeQuestions?.sections.map(s => {
            const savedSection: VcaTypes.ISavedCheckSection = {
                tag: s.sectionName,
                sectionOptionalQuestion: s.sectionOptionalQuestion,
                questions: s.questions.map(q => {
                    const savedQuestion: VcaTypes.ISavedCheckQuestion = {
                        answer: null,
                        failReason: null,
                        allowNa: q.allowNa,
                        question: q.question,
                    };
                    return savedQuestion;
                }),
            };
            return savedSection;
        });

        const check: VcaTypes.ISavedCheck = {
            startedAt: new Date(),
            objectTypeId: result.objectTypeId,
            odometerReadingKm: result.odometerReadingKm,
            odometerReadingDate: result.vehicle.odometerReadingDate,
            completedAt: null,
            isComplete: false,
            isSynchronised: false,
            modifiedObjectTypeId: result.modifiedObjectTypeId,
            modifiedMakeModel: result.modifiedMakeModel,
            modifiedRegistration: result.modifiedRegistration,
            objectId: result.vehicle.objectId,
            signaturePng: null,
            sections: checkSections,
            registration: result.modifiedRegistration || result.vehicle.registration || "",
            number: result.vehicle.number,
            synchronisedAt: null,
            vcaQuestionProfileId: result.vcaQuestionProfileId,
            questionProfile:
                "name" in typeQuestions
                    ? `Custom Profile: ${typeQuestions.name} (${typeQuestions.profileId})`
                    : `Standard Profile: ${
                          this.state.vehicleTypes.find(i => i.objectTypeId === result.objectTypeId)
                              ?.objectTypeName ?? "Unknown"
                      } (${typeQuestions.objectTypeId})`,
            odometerRequirementIntervalHours: typeQuestions.odometerRequirementIntervalHours
        };

        

        var key = LocalStorageDal.saveCheck(check);

        this.props.history.replace(`/checklist/${key}`);
    };

    scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    componentDidMount = () => {
        this.scrollToTop();
    };

    render() {
        let shownPanel: any;

        if (!this.state.availableVehicles.length) {
            shownPanel = (
                <div className="alert alert-warning">
                    No vehicles available to select from. Try synchronising first.
                </div>
            );
        } else {
            if (!this.state.selectedVehicle) {
                shownPanel = (
                    <VehicleSelector
                        availableVehicles={this.state.availableVehicles}
                        vehicleSelected={this.handleVehicleSelected}
                    />
                );
            } else {
                shownPanel = (
                    <SelectedVehicle
                        vehicle={this.state.selectedVehicle}
                        vehicleTypes={this.state.vehicleTypes}
                        vehicleTypesWithQuestions={this.state.vehicleTypesWithQuestions}
                        checkStarted={this.handleCheckStarted}
                    />
                );
            }
        }

        return (
            <div>
                {shownPanel}
                <hr />
                <div className="form-group">
                    <a className="btn btn-secondary" href="/">
                        Cancel
                    </a>
                </div>
            </div>
        );
    }
}
