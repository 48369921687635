import React, { Component, createRef } from 'react';

interface IEditableVehiclePropertyState {
}

export interface IEditableVehiclePropertyProps {
    inputName: string;
    fieldLabel: string;
    originalValue: string;
    editedValue: string;
    isChanged: boolean;
    isValueValid: boolean | null;
    focusInput: boolean;
    valueChanged: (isEdited: boolean, editedValue: string | null) => void;
    isMandatory: boolean;
}

export class EditableVehicleProperty extends Component<IEditableVehiclePropertyProps, IEditableVehiclePropertyState> {
   
    private input = createRef<HTMLInputElement>();

    componentDidUpdate() {
        if (this.props.focusInput) {
            const node = this.input.current;
            if (node) {
                node.focus();
            }
        }
    }

    handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.valueChanged(this.props.isChanged || this.props.isMandatory, event.target.value);
    };

    handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.valueChanged(event.target.checked, this.props.editedValue);
    };

    render() {

        const isEditable = (this.props.isChanged || this.props.isMandatory);

        const isInvalid = isEditable && this.props.isValueValid === false;

        const inputClassName = isInvalid
            ? "form-control is-invalid"
            : "form-control";

        const inputId = `chosen-vehicle-${this.props.inputName}`;
        const allowChangeId = `${inputId}-allowChange`;

        const valueToUse = (isEditable ? this.props.editedValue : this.props.originalValue) || "";

        return (<div className="form-group">
            <label htmlFor={inputId}>{this.props.fieldLabel}</label>
            <div className="input-group">
                <input ref={this.input} readOnly={!isEditable} type="text" id={inputId} className={inputClassName} value={valueToUse} onChange={this.handleTextChange} />
                {!this.props.isMandatory && <div className="input-group-append">
                    <div className="input-group-text"><input type="checkbox" id={allowChangeId
                    } className="form-check-input ml-0" defaultChecked={this.props.isChanged} onChange={this.handleCheckChange} /><label className="form-check-label ml-3" htmlFor={allowChangeId
                    }> Update</label></div></div>}
            </div>
            {isInvalid && <div className="invalid-feedback">Please provide a {this.props.fieldLabel}.</div>}
        </div>);
    }

}