import React, { Component } from "react";

export interface IHomeListCheck {
    reference: string;
    objectNumber: string;
    objectRegistration: string;
    startedAt: Date;
    completedAt: Date | null;
    syncdAt: Date | null;
}

export interface IHomeCheckListControlProps {
    checks: IHomeListCheck[];
    newCheckStarted: () => void;
    incompleteCheckSelected: (key: string) => void;
    syncNowButton: (value: boolean) => React.ReactChild;
}

interface IHomeCheckListControlState {
    isNavigating: boolean;
}

interface ICompletedCheck extends IHomeListCheck {
    absCompletedAt: Date;
}

interface ISyncdCheck extends ICompletedCheck {
    absSyncdAt: Date;
}

export class HomeCheckListControl extends Component<
    IHomeCheckListControlProps,
    IHomeCheckListControlState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            isNavigating: false,
        };
    }

    handleStartCheckClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({ isNavigating: true });
        this.props.newCheckStarted();
    };

    handleCheckSelected = (key: string) => {
        this.setState({ isNavigating: true });
        this.props.incompleteCheckSelected(key);
    };

    render() {
        const incompleteChecks: IHomeListCheck[] = [];
        const completeChecks: ICompletedCheck[] = [];
        const syncdChecks: ISyncdCheck[] = [];

        function insertLatestStartFirst<T extends IHomeListCheck>(arr: Array<T>, element: T) {
            let insertionIndex: number;
            for (insertionIndex = 0; insertionIndex < arr.length; insertionIndex++) {
                if (element.startedAt > arr[insertionIndex].startedAt) {
                    break;
                }
            }

            arr.splice(insertionIndex, 0, element);
        }

        if (this.props.checks) {
            this.props.checks.forEach(c => {
                if (!c.completedAt) {
                    insertLatestStartFirst(incompleteChecks, c);
                } else if (!c.syncdAt) {
                    const completedCheck: ICompletedCheck = { ...c, absCompletedAt: c.completedAt };
                    insertLatestStartFirst(completeChecks, completedCheck);
                } else {
                    const syncdCheck: ISyncdCheck = {
                        ...c,
                        absCompletedAt: c.completedAt,
                        absSyncdAt: c.syncdAt,
                    };
                    insertLatestStartFirst(syncdChecks, syncdCheck);
                }
            });
        }

        const hasIncompleteChecks = !!incompleteChecks.length;

        return (
            <div>
                <div className="btn-with-help align-items-start">
                    <button
                        // disabled={this.state.isNavigating || hasIncompleteChecks}
                        className={`btn btn-lg btn-${
                            this.state.isNavigating || hasIncompleteChecks
                                ? "secondary disabled"
                                : "primary"
                        }`}
                        onClick={
                            this.state.isNavigating || hasIncompleteChecks
                                ? () => alert("Please finish your incomplete check first.")
                                : this.handleStartCheckClick
                        }>
                        Start Check
                    </button>
                    {this.props.syncNowButton(completeChecks.length > 0)}
                </div>
                {hasIncompleteChecks &&
                    incompleteChecks.map(c => {
                        return (
                            <IncompleteCheckRow
                                key={c.reference}
                                check={c}
                                isNavigating={this.state.isNavigating}
                                checkSelected={this.handleCheckSelected}
                            />
                        );
                    })}
                {completeChecks.map(c => {
                    return (
                        <CompleteCheckRow
                            key={c.reference}
                            check={c}
                            isNavigating={this.state.isNavigating}
                            checkSelected={this.handleCheckSelected}
                        />
                    );
                })}
                {syncdChecks.map(c => {
                    return (
                        <SyncdCheckRow
                            key={c.reference}
                            check={c}
                            isNavigating={this.state.isNavigating}
                            checkSelected={this.handleCheckSelected}
                        />
                    );
                })}
            </div>
        );
    }
}

interface IIncompleteCheckRowProps {
    check: IHomeListCheck;
    checkSelected: (key: string) => void;
    isNavigating: boolean;
}

interface IIncompleteCheckRowState {}

class IncompleteCheckRow extends Component<IIncompleteCheckRowProps, IIncompleteCheckRowState> {
    handleContinueCheckClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        this.props.checkSelected(this.props.check.reference);
    };

    render() {
        const check = this.props.check;

        return (
            <div className="alert alert-primary">
                <h4 className="alert-heading">Incomplete check</h4>
                <div className="form-group">
                    <div>
                        <b>Started: </b>
                        {check.startedAt.toLocaleString()}
                    </div>
                    <div>
                        <b>Vehicle:</b> {check.objectNumber} - {check.objectRegistration}
                    </div>
                </div>
                <div>
                    <button
                        disabled={this.props.isNavigating}
                        className="btn btn-primary"
                        onClick={this.handleContinueCheckClick}>
                        Continue
                    </button>
                </div>
            </div>
        );
    }
}

interface ICompleteCheckRowProps {
    check: ICompletedCheck;
    checkSelected: (key: string) => void;
    isNavigating: boolean;
}

class CompleteCheckRow extends Component<ICompleteCheckRowProps, any> {
    handleContinueCheckClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        this.props.checkSelected(this.props.check.reference);
    };

    render() {
        const check = this.props.check;

        return (
            <div className="alert alert-warning">
                <h4 className="alert-heading">Completed check</h4>
                <div className="form-group">
                    <div>
                        <b>Sync status: not sent</b>
                    </div>
                    <div>
                        <b>Started: </b> {check.startedAt.toLocaleString()}
                    </div>
                    <div>
                        <b>Finished:</b> {check.absCompletedAt.toLocaleString()}
                    </div>
                    <div>
                        <b>Vehicle:</b> {check.objectNumber} - {check.objectRegistration}
                    </div>
                </div>
                <div>
                    <button
                        disabled={this.props.isNavigating}
                        className="btn btn-outline-dark"
                        onClick={this.handleContinueCheckClick}>
                        View Details
                    </button>
                </div>
            </div>
        );
    }
}

interface ISyncdCheckRowProps {
    check: ISyncdCheck;
    checkSelected: (key: string) => void;
    isNavigating: boolean;
}

class SyncdCheckRow extends Component<ISyncdCheckRowProps, any> {
    handleContinueCheckClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        this.props.checkSelected(this.props.check.reference);
    };

    render() {
        const check = this.props.check;

        return (
            <div className="alert alert-success">
                <h4 className="alert-heading">Completed check</h4>
                <div className="form-group">
                    <div>
                        <b>Sync status:</b> received {check.absSyncdAt.toLocaleString()}
                    </div>
                    <div>
                        <b>Started: </b> {check.startedAt.toLocaleString()}
                    </div>
                    <div>
                        <b>Finished:</b> {check.absCompletedAt.toLocaleString()}
                    </div>
                    <div>
                        <b>Vehicle:</b> {check.objectNumber} - {check.objectRegistration}
                    </div>
                </div>
                <div>
                    <button
                        disabled={this.props.isNavigating}
                        className="btn btn-outline-dark"
                        onClick={this.handleContinueCheckClick}>
                        View Details
                    </button>
                </div>
            </div>
        );
    }
}
