import React, { Component } from "react";
import { YesNoQuestion } from "./YesNoQuestion";
import { Link, RouteComponentProps } from "react-router-dom";
import { LocalStorageDal } from "../LocalStorageDal";
import * as VcaTypes from "../VcaTypes";
import Popup from "reactjs-popup";

type ChecklistRouteParams = { key?: string };

interface ICheckListProps extends RouteComponentProps<ChecklistRouteParams> { }

interface ICheckListState {
    sections: ISection[];
    questionProfile: string;
    isValid: boolean;
    isComplete: boolean;
    focusError: boolean;
    isSubmitting: boolean;
    odometerIsValid: boolean | null;
    odometerReading?: string | null;
    odometerUnits: string;
    odometerRequirementIntervalHours?: number | null;
}

interface IQuestion {
    question: string;
    allowNa: boolean;
    answer: string | null;
    failReason: string | null;
    focusError: boolean;
    isAnswerValid: boolean | null;
    isFailReasonValid: boolean | null;
}

interface ISection {
    tag: string;
    questions: IQuestion[];
    sectionOptionalQuestion?: string | null;
    sectionOptionalAnswer?: boolean;
}

interface IParseOdometerResult {
    value: number | null;
    isValid: boolean;
}

class QuestionData {
    sectionIdx: number;
    questionIdx: number;
    question: IQuestion;

    constructor(sectionIdx: number, questionIdx: number, question: IQuestion) {
        this.sectionIdx = sectionIdx;
        this.questionIdx = questionIdx;
        this.question = question;
    }
}

export class CheckList extends Component<ICheckListProps, ICheckListState> {
    alertMessage: React.RefObject<HTMLDivElement>;

    private savedCheck: VcaTypes.ISavedCheck | null;
    private checklistKey: string | null;

    constructor(props: ICheckListProps) {
        super(props);

        this.alertMessage = React.createRef<HTMLDivElement>();

        this.checklistKey = this.props.match ? this.props.match.params.key || null : null;

        if (this.checklistKey) {
            this.savedCheck = LocalStorageDal.getCheck(this.checklistKey);
        } else {
            this.savedCheck = null;
        }

        if (!this.savedCheck) {
            // || this.savedCheck.isComplete) {
            this.props.history.replace("/");
        } else {
            const sections: ISection[] = this.savedCheck.sections.map(s => {
                const section: ISection = {
                    tag: s.tag,
                    sectionOptionalQuestion: s.sectionOptionalQuestion,
                    sectionOptionalAnswer: s.sectionOptionalAnswer,
                    questions: s.questions.map(q => {
                        const question: IQuestion = {
                            question: q.question,
                            allowNa: q.allowNa,
                            answer: q.answer,
                            failReason: q.failReason,
                            isFailReasonValid: null,
                            isAnswerValid: null,
                            focusError: false,
                        };
                        return question;
                    }),
                };
                return section;
            });
            const odometerValue = this.savedCheck.odometerReadingKm
                ? (this.savedCheck.odometerUnits !== "km"
                    ? this.savedCheck.odometerReadingKm / 1.609344
                    : this.savedCheck.odometerReadingKm
                ).toString()
                : null;

            this.state = {
                questionProfile: this.savedCheck.questionProfile,
                sections: sections,
                focusError: false,
                isValid: true,
                isComplete: this.savedCheck.isComplete,
                isSubmitting: false,
                odometerUnits: this.savedCheck.odometerUnits ?? "miles",
                odometerReading: odometerValue,
                odometerRequirementIntervalHours: this.savedCheck.odometerRequirementIntervalHours,
                odometerIsValid: this.validateOdometer(
                    odometerValue,
                    this.savedCheck.odometerRequirementIntervalHours ?? undefined
                ).isValid
            };


        }
    }

    validateQuestion(q: IQuestion, s: ISection): void {
        q.isAnswerValid = !!s.sectionOptionalQuestion && s.sectionOptionalAnswer === false;
        q.isFailReasonValid = true;
        if (q.answer) {
            switch (q.answer) {
                case "Pass":
                    q.isAnswerValid = true;
                    break;
                case "Fail":
                    q.isAnswerValid = true;
                    q.isFailReasonValid = !!q.failReason && q.failReason.length > 3;
                    break;
                case "N/A":
                    q.isAnswerValid = q.allowNa;
                    break;
            }
        }
    }

    forEachQuestion = (sections: ISection[], callback: (question: QuestionData) => void) => {
        sections.forEach((s, si) =>
            s.questions.forEach((q, qi) => callback(new QuestionData(si, qi, q)))
        );
    };

    handleQuestionStateChange = (
        sectionIdx: number,
        questionIdx: number,
        newAnswer: string | null,
        newFailureReason: string | null
    ) => {
        const arr = this.state.sections.slice();
        const question = arr[sectionIdx].questions[questionIdx];
        const oldAnswer = question.answer;
        question.answer = newAnswer;
        question.failReason = newFailureReason;
        if (question.isAnswerValid !== null) {
            this.validateQuestion(question, arr[sectionIdx]);
        }

        if (newAnswer === YesNoQuestion.ANSWER_FAIL && oldAnswer !== YesNoQuestion.ANSWER_FAIL) {
            this.forEachQuestion(
                arr,
                q =>
                    (q.question.focusError =
                        q.sectionIdx === sectionIdx && q.questionIdx === questionIdx)
            );
        } else {
            this.forEachQuestion(arr, q => (q.question.focusError = false));
        }

        this.setSectionsState(arr);

        if (this.savedCheck) {
            this.savedCheck.sections = arr.map(s => {
                const section: VcaTypes.ISavedCheckSection = {
                    tag: s.tag,
                    sectionOptionalQuestion: s.sectionOptionalQuestion,
                    sectionOptionalAnswer: s.sectionOptionalAnswer,
                    questions: s.questions.map(q => {
                        const savedQuestion: VcaTypes.ISavedCheckQuestion = {
                            question: q.question,
                            answer: q.answer,
                            failReason: q.failReason,
                            allowNa: q.allowNa,
                        };
                        return savedQuestion;
                    }),
                };
                return section;
            });

            LocalStorageDal.saveCheck(this.savedCheck);
        }
    };

    handleSectionOptionalAnswer = (sectionIdx: number, newAnswer: string | null) => {
        const arr = this.state.sections.slice();
        const section = arr[sectionIdx];
        section.sectionOptionalAnswer = newAnswer === "Pass";

        this.setSectionsState(arr);

        if (this.savedCheck) {
            this.savedCheck.sections = arr.map(s => {
                const section: VcaTypes.ISavedCheckSection = {
                    tag: s.tag,
                    sectionOptionalQuestion: s.sectionOptionalQuestion,
                    sectionOptionalAnswer: s.sectionOptionalAnswer,
                    questions: s.questions.map(q => {
                        const savedQuestion: VcaTypes.ISavedCheckQuestion = {
                            question: q.question,
                            answer: q.answer,
                            failReason: q.failReason,
                            allowNa: q.allowNa,
                        };
                        return savedQuestion;
                    }),
                };
                return section;
            });

            LocalStorageDal.saveCheck(this.savedCheck);
        }
    };

    validate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();
        var arr = this.state.sections.slice();
        this.forEachQuestion(arr, q => this.validateQuestion(q.question, arr[q.sectionIdx]));
        var isValid = this.setSectionsState(arr);
        this.setState({
            focusError: !isValid,
            isSubmitting: isValid,
            odometerIsValid: this.validateOdometer(this.state.odometerReading ?? "").isValid
        });
        if (isValid) {
            this.props.history.push(`/sign/${this.checklistKey}`);
        }
    };

    setSectionsState = (sections: ISection[]) => {
        let isValid = true;
        isValid = isValid && (this.state.odometerIsValid || false);
        sections.forEach(section => {
            isValid =
                isValid &&
                (!section.sectionOptionalQuestion || section.sectionOptionalAnswer !== undefined);
        });
        this.forEachQuestion(sections, q => {
            if (q.question.isAnswerValid === false || q.question.isFailReasonValid === false) {
                isValid = false;
            }
        });
        this.setState({ sections: sections, isValid: isValid, focusError: false });
        return isValid;
    };

    componentDidMount = () => {
        window.scrollTo(0, 0);
    };

    componentDidUpdate() {
        if (this.state.focusError) {
            const node = this.alertMessage.current;
            if (node) {
                window.scrollTo(0, node.offsetTop);
            }
        }
    }

    deleteCheck = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (this.savedCheck) {
            LocalStorageDal.deleteCheck(this.savedCheck);
        }
        this.props.history.push("/");
    };

    getOdometerRequirementDate = (odometerRequirementIntervalHours?: number) => {
        odometerRequirementIntervalHours = odometerRequirementIntervalHours || this.state?.odometerRequirementIntervalHours || -1;
        return (
            new Date(this.savedCheck?.odometerReadingDate ?? 0).valueOf() +
            3600000 * odometerRequirementIntervalHours
        );
    };

    checkOdometerDate = (odometerRequirementIntervalHours?: number) => {
        const lastDate = new Date(this.savedCheck?.odometerReadingDate ?? 0).valueOf() / 3600000;
        return (
            Date.now() / 3600000 -
            ((odometerRequirementIntervalHours ??
                this.state?.odometerRequirementIntervalHours) ||
                0) <
            lastDate
        );
    };

    validateOdometer = (
        value?: string | null,
        odometerRequirementIntervalHours?: number
    ): IParseOdometerResult => {
        const parsedOdometerReading = value ? parseInt(value) : null;
        let isValid = !!parsedOdometerReading;
        if (
            ((odometerRequirementIntervalHours ?? this.state?.odometerRequirementIntervalHours) ||
                0) < 0 &&
            !value
        ) {
            isValid = true;
        }
        if (this.checkOdometerDate(odometerRequirementIntervalHours) && !value) {
            isValid = true;
        }
        return { value: parsedOdometerReading, isValid: isValid };
    };

    applyUpdatedOdometerToSavedCheck = (newState: ICheckListState) => {
        if (this.savedCheck) {
            const validated = this.validateOdometer(newState.odometerReading || "");
            if (validated.value !== null) {
                this.savedCheck.odometerReadingKm =
                    this.state.odometerUnits === "miles" ? validated.value * 1.609344 : validated.value;
            } else {
                this.savedCheck.odometerReadingKm = null;
            }
            LocalStorageDal.saveCheck(this.savedCheck);
        }
    };

    handleOdometerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        var newState: ICheckListState = { ...this.state, odometerReading: event.target.value };
        const validated = this.validateOdometer(newState.odometerReading);
        newState.odometerIsValid = validated.isValid;
        this.setState(newState);

        this.applyUpdatedOdometerToSavedCheck(newState);
    };

    handleOdometerUnitsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        var newState: ICheckListState = { ...this.state, odometerUnits: event.target.value };

        this.setState(newState);

        this.applyUpdatedOdometerToSavedCheck(newState);
    };

    formatDate = (date: Date) => {
        return `${date.toLocaleDateString()} ${date
            .getHours()
            .toString()
            .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    };

    render() {
        const odometerInputId = "chosen-vehicle-odometer";
        const odometerClass =
            this.state.isComplete || this.state?.odometerIsValid !== false
                ? "form-control"
                : "form-control is-invalid";
        if (this.state) {
            return (
                <div>
                    {this.state.isComplete && <Link to="/" className="btn btn-link pl-0">Return to Home Screen</Link>}
                    <h3>Odometer Reading</h3>
                    {(this.state.odometerRequirementIntervalHours || 0) >= 0 ? (
                        <div className="form-group">
                            <label htmlFor={odometerInputId}>Odometer reading</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select
                                        disabled={this.state.isComplete}
                                        value={this.state.odometerUnits}
                                        onChange={this.handleOdometerUnitsChange}>
                                        <option value="miles">miles</option>
                                        <option value="km">KM</option>
                                    </select>
                                </div>
                                <input
                                    type="number"
                                    id={odometerInputId}
                                    className={odometerClass}
                                    defaultValue={this.state.odometerReading || ""}
                                    onChange={this.handleOdometerChange}
                                    readOnly={this.state.isComplete}
                                />
                            </div>
                            <small className="form-text text-muted">
                                {this.checkOdometerDate() &&
                                    `Optional, Next Required: ${this.formatDate(
                                        new Date(this.getOdometerRequirementDate())
                                    )}`}
                            </small>
                            {!this.state.odometerIsValid && (
                                <div className="invalid-feedback">
                                    Please provide an Odometer reading.
                                </div>
                            )}
                        </div>
                    ) : (
                            <h6 className="text-muted">
                                An Odometer Reading is not required for this vehicle.
                            </h6>
                        )}
                    {this.state.sections.map((section, sectionIndex) => {
                        return (
                            <div key={sectionIndex}>
                                <h3>{section.tag}</h3>
                                <ol className="checkList">
                                    {!!section.sectionOptionalQuestion && (
                                        <div className="section-optional">
                                            <YesNoQuestion
                                                readOnly={this.state.isComplete}
                                                question={section.sectionOptionalQuestion}
                                                answer={
                                                    section.sectionOptionalAnswer === undefined
                                                        ? null
                                                        : section.sectionOptionalAnswer
                                                            ? "Pass"
                                                            : "Fail"
                                                }
                                                passLabel="Yes"
                                                failLabel="No"
                                                nonFailure
                                                changeState={ns => {
                                                    this.handleSectionOptionalAnswer(
                                                        sectionIndex,
                                                        ns
                                                    );
                                                }}
                                                failReason={null}
                                                focusError={false}
                                                isAnswerValid={
                                                    !this.state.focusError ||
                                                    section.sectionOptionalAnswer !== undefined
                                                }
                                            />
                                        </div>
                                    )}
                                    {(!section.sectionOptionalQuestion ||
                                        section.sectionOptionalAnswer) &&
                                        section.questions.map((q, qi) => {
                                            return (
                                                <YesNoQuestion
                                                    readOnly={this.state.isComplete}
                                                    key={qi}
                                                    allowNa={q.allowNa}
                                                    question={q.question}
                                                    answer={q.answer}
                                                    changeState={(ns, nr) =>
                                                        this.handleQuestionStateChange(
                                                            sectionIndex,
                                                            qi,
                                                            ns,
                                                            nr
                                                        )
                                                    }
                                                    focusError={q.focusError}
                                                    isAnswerValid={q.isAnswerValid}
                                                    isFailReasonValid={q.isFailReasonValid}
                                                    failReason={q.failReason}
                                                />
                                            );
                                        })}
                                    {!!section.sectionOptionalQuestion &&
                                        section.sectionOptionalAnswer === false && (
                                            <h6 className="text-muted section-optional-text">
                                                The {section.tag} questions are not required for
                                                this vehicle.
                                            </h6>
                                        )}
                                </ol>
                            </div>
                        );
                    })}

                    {!this.state.isComplete && (
                        <div className="checklist-submit-row">
                            <div className="form-group">
                                <button className="btn btn-primary" onClick={this.validate}>
                                    Sign &amp; submit
                                </button>
                                <Popup
                                    modal
                                    className="vca-popup-content"
                                    trigger={
                                        <button className="btn btn-secondary float-right">
                                            Discard
                                        </button>
                                    }
                                    closeOnDocumentClick={false}>
                                    {(close: any) => (
                                        <div
                                            className="modal fade show modal-show-always"
                                            role="dialog">
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">
                                                            Discard check
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="close"
                                                            onClick={close}
                                                            aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p>
                                                            Are you sure you want to delete this
                                                            check?
                                                        </p>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={this.deleteCheck}>
                                                            Delete
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            onClick={close}>
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>{" "}
                            {!this.state.isValid && (
                                <div ref={this.alertMessage} className="alert alert-danger">
                                    Please correct the errors.
                                </div>
                            )}
                            <div className="text-muted">{this.state.questionProfile}</div>
                        </div>
                    )}
                </div>
            );
        }

        return <div>Checklist not found</div>;
    }
}
