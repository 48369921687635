import React, { Component } from 'react';
import { HomeCheckListControl, IHomeListCheck } from "../components/HomeCheckListControl";
import { LocalStorageDal } from '../LocalStorageDal';
import { History } from "history";
import { Sync, SyncResponseStatus } from "../Sync"


interface IHomeProps {
    history: History;
}



interface IHomeState {
    checks: IHomeListCheck[];
    isSynchronising: boolean;
    syncHasFailed: boolean;
    syncMessage: string;
}

export class Home extends Component<IHomeProps, IHomeState> {
    static displayName = Home.name;

    updateCheckStateFromLocalStorage(state: IHomeState) {
        const checks = LocalStorageDal.listChecks();
        state.checks = checks.map(c => {
            const result: IHomeListCheck = {
                startedAt: c.startedAt,
                objectNumber: c.number,
                objectRegistration: c.modifiedRegistration || c.registration,
                reference: LocalStorageDal.getCheckKey(c),
                completedAt: c.completedAt,
                syncdAt: c.synchronisedAt
            };
            return result;
        });
    }

    checkStatusChanged = () => {
        var newState: IHomeState = {
            checks: [],
            isSynchronising: this.state.isSynchronising,
            syncHasFailed: this.state.syncHasFailed,
            syncMessage: this.state.syncMessage,
        }
        this.updateCheckStateFromLocalStorage(newState);
        newState.isSynchronising = this.state.isSynchronising;
        newState.syncHasFailed = this.state.syncHasFailed;
        newState.syncMessage = this.state.syncMessage;

        this.setState(newState);
    }


    syncClick = () => {
        this.setState({ isSynchronising: true, syncHasFailed: false, syncMessage: "Synchronising..." });
        Sync.syncNow().then(r => {

            switch (r.status) {
                case SyncResponseStatus.Unauthorised:
                    {
                        alert("You are not logged in");
                        this.props.history.push("/login");
                        break;
                    }
                case SyncResponseStatus.Error:
                {
	                throw r.message;
	                break;
                }
                case SyncResponseStatus.Success:
                {
                    this.setState({ syncHasFailed: false, syncMessage: 'Sync complete' });
                    break;
                }

                case SyncResponseStatus.AlreadySyncing:
                {
                    this.setState({ syncHasFailed: false, syncMessage: 'Sync already in progress' });
                    break;
                }
            }
        })
            .catch(error => {
                this.setState({ syncHasFailed: true, syncMessage: `Sync failed. ${error}` });
            })
            .finally(() => this.setState({ isSynchronising: false }));
    };

    startCheckClick = () => {
        this.props.history.push("/start-check");
    };

    viewCheckClick = (key: string) => {
        this.props.history.push(`/checklist/${key}`);
    };

    constructor(props: any) {
        super(props);

        const state: IHomeState = {
            checks: [],
            isSynchronising: false,
            syncHasFailed: false,
            syncMessage: ""
        };

        this.updateCheckStateFromLocalStorage(state);
        LocalStorageDal.checkStatusChanged.on(this.checkStatusChanged);
        this.state = state;
    }

    componentWillUnmount = () => {
        LocalStorageDal.checkStatusChanged.off(this.checkStatusChanged);
    }

    render() {
        const btnClass = this.state.syncHasFailed
            ? "ml-auto btn btn-lg btn-warning"
            : "ml-auto btn btn-lg btn-secondary";
        const msgClass = this.state.isSynchronising ? "alert alert-info" : this.state.syncHasFailed ? "alert alert-danger" : "alert alert-success";
        return (
            <div>
                {/* <div className="form-group"></div> */}
                {this.state.syncMessage && <div className={msgClass}>{this.state.syncMessage}</div>}
                <HomeCheckListControl
                    newCheckStarted={this.startCheckClick}
                    incompleteCheckSelected={this.viewCheckClick}
                    checks={this.state.checks}
                    syncNowButton={
                        i => <button
                            onClick={this.syncClick}
                            className={i ? "ml-auto btn btn-lg btn-warning" : btnClass}
                            disabled={this.state.isSynchronising}>
                            Sync Now
                        </button>
                    }
                />
            </div>
        );
    }
}
