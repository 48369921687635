import React, { Component } from 'react';
import { LocalStorageDal } from "../LocalStorageDal";



export class Insurance extends React.Component {
   


    render() {

        
        return (
            <div>
				<h3>Breakdown & Insurance Details</h3>
                <div className="insurance-details">
					{LocalStorageDal.loadInsuranceDetails()}
				</div>
                </div>
        );
    }

}