import React, { Component, createRef } from 'react';


interface IYesNoQuestionState {
}

interface IYesNoQuestionProps {
    readOnly?: boolean;
    question: string;
    allowNa?: boolean;
    answer: string | null;
    failReason: string | null;
    focusError: boolean;
    changeState?(value: string | null, failReason: string | null): void;
    isAnswerValid?: boolean | null;
    isFailReasonValid?: boolean | null;
    passLabel?: string;
    failLabel?: string;
    naLabel?: string;
    nonFailure?: boolean;
}


export class YesNoQuestion extends Component<IYesNoQuestionProps, IYesNoQuestionState> {

    // ReSharper disable InconsistentNaming
    public static readonly ANSWER_PASS = "Pass";
    public static readonly ANSWER_FAIL = "Fail";
    public static readonly ANSWER_NA = "N/A";
    // ReSharper restore InconsistentNaming

    private input = createRef<HTMLInputElement>();


    handleChangeAnswer = (newState: string) => {
        this.props.changeState?.(newState, this.props.failReason);
    }

    handleChangeFailReason = (e: React.FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        this.props.changeState?.(this.props.answer, newValue);
    }

    componentDidUpdate() {
        if (this.props.focusError) {
            const node = this.input.current;
            if (node) {
                node.focus();
            }
        }
    }

    render() {
        const classNamesArray = ["question-row"];

        if (!this.props.nonFailure)
            switch (this.props.answer) {
                case YesNoQuestion.ANSWER_PASS:
                    classNamesArray.push("question-row-pass");
                    break;
                case YesNoQuestion.ANSWER_FAIL:
                    classNamesArray.push("question-row-fail");
                    break;
                case YesNoQuestion.ANSWER_NA:
                    classNamesArray.push("question-row-na");
                    break;
            }

        if (this.props.isAnswerValid === false) {
            classNamesArray.push("question-row-answer-invalid");
        }

        if (this.props.isFailReasonValid === false) {
            classNamesArray.push("question-row-fail-reason-invalid");
        }

        var isValid = !(this.props.isAnswerValid === false || this.props.isFailReasonValid === false);

        const classNames = classNamesArray.join(" ");

        return (
            <li>
                <div className={classNames}>
                    <div className="question-cell">
                        {!isValid && <span className="question-alert">!</span>}{" "}
                        {this.props.question}
                    </div>
                    <div>
                        <span className="toggle-button">
                            <ToggleButton
                                readOnly={this.props.readOnly}
                                selectedClassName="btn-pass"
                                label={this.props.passLabel}
                                state={YesNoQuestion.ANSWER_PASS}
                                selectedState={this.props.answer}
                                changeState={this.handleChangeAnswer}
                            />
                            <ToggleButton
                                readOnly={this.props.readOnly}
                                selectedClassName="btn-fail"
                                label={this.props.failLabel}
                                state={YesNoQuestion.ANSWER_FAIL}
                                selectedState={this.props.answer}
                                changeState={this.handleChangeAnswer}
                            />
                            {this.props.allowNa && (
                                <ToggleButton
                                    readOnly={this.props.readOnly}
                                    selectedClassName="btn-na"
                                    label={this.props.naLabel}
                                    state={YesNoQuestion.ANSWER_NA}
                                    selectedState={this.props.answer}
                                    changeState={this.handleChangeAnswer}
                                />
                            )}
                        </span>
                    </div>
                    {this.props.answer === YesNoQuestion.ANSWER_FAIL && !this.props.nonFailure && (
                        <input
                            ref={this.input}
                            type="text"
                            required
                            className="form-control ctl-fail"
                            value={this.props.failReason || ""}
                            placeholder="Reason for failure (min 4 characters)"
                            onChange={this.handleChangeFailReason}
                        />
                    )}
                </div>
            </li>
        );
    }
}

interface IToggleButtonProps {
    selectedClassName: string;
    selectedState: string | null;
    state: string;
    label?: string;
    changeState(value: string): void;
    readOnly?: boolean;
}

class ToggleButton extends Component<IToggleButtonProps, any> {
  

    render() {
        const classNamesArray = ["btn"];

        if (this.props.selectedState === this.props.state) {
            classNamesArray.push(this.props.selectedClassName);
        } else {
            classNamesArray.push("btn-default");
        }


        const classNames = classNamesArray.join(" ");

        return (
            <button
                disabled={this.props.readOnly}
                style={{ opacity: 1 }}
                className={classNames}
                onClick={this.props.readOnly ? undefined : () => this.props.changeState(this.props.state)}>
                {this.props.label ?? this.props.state}
            </button>
        );
    }
}