import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { LocalStorageDal } from "../LocalStorageDal";
import * as VcaTypes from "../VcaTypes";
import SignaturePad from "react-signature-canvas";
import { Sync } from "../Sync";

type SignCheckRouteParams = { key?: string }

interface ISignCheckProps extends RouteComponentProps<SignCheckRouteParams> {
}

interface ISignCheckState {
    isValid: boolean;
}


export class SignCheck extends Component<ISignCheckProps, ISignCheckState> {

    private readonly savedCheck: VcaTypes.ISavedCheck | null;
    private readonly checklistKey: string | null;
    private readonly alertMessage: React.RefObject<HTMLDivElement>;
    private readonly container: React.RefObject<HTMLDivElement>;
    private readonly canvasRef: React.RefObject<SignaturePad>;

    constructor(props: ISignCheckProps) {
        super(props);

        this.canvasRef = React.createRef<SignaturePad>();
        this.alertMessage = React.createRef<HTMLDivElement>();
        this.container = React.createRef<HTMLDivElement>();

        this.checklistKey = this.props.match ? this.props.match.params.key || null : null;

        if (this.checklistKey) {
            this.savedCheck = LocalStorageDal.getCheck(this.checklistKey);
        } else {
            this.savedCheck = null;
        }

        if (!this.savedCheck || this.savedCheck.isComplete) {
            this.props.history.replace("/");
        } else {
            this.state = {
                isValid: true
            };
        }
    }

    submit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();
        if (this.savedCheck) {
            const canvas: any = this.canvasRef.current;
            if (canvas.isEmpty()) {
                this.setState({ isValid: false });
                return;
            }

            const png = canvas.getTrimmedCanvas().toDataURL("image/PNG");
            this.savedCheck.completedAt = new Date();
            this.savedCheck.isComplete = true;
            this.savedCheck.signaturePng = png;
            LocalStorageDal.saveCheck(this.savedCheck);
            Sync.trySyncNow();
        }
        this.props.history.push(`/check-complete`);
    }

    cancelSign = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        this.props.history.push(`/checklist/${this.checklistKey}`);
    }

    updateScroll = () => {
        if (this.state) {
            const node = this.state.isValid ? this.container.current : this.alertMessage.current;
            if (node) {
                window.scrollTo(0, node.offsetTop - 3);
            }
        }

    }

    componentDidMount() {
        this.updateScroll();
    }

    componentDidUpdate() {
        this.updateScroll();
    }

    render() {


        if (this.state) {
            const sigClass = this.state.isValid ? "signature-canvas" : "signature-canvas error";
            return (
                <div className="signature-canvas-container">
                    <div ref={this.container}>
                        <SignaturePad ref={this.canvasRef} canvasProps={{ className: sigClass }} />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-secondary float-right" onClick={this.cancelSign}>Cancel</button>
                        <button className="btn btn-primary" onClick={this.submit}>Submit</button>
                    </div>
                    {!
                        this.state.isValid &&
                        <div ref={this.alertMessage} className="alert alert-danger">
                            Please sign and try again.
                                </div>
                    }
                    {this.state.isValid &&
                        <div className="alert alert-info">By signing this you agree that the vehicle is fit to drive and that all defects have been reported to the office.</div>
                    }
                </div>

            );
        }

        return (<div>Checklist not found</div>);
    }
}