import { LiteEvent } from "./LiteEvent";
import * as VcaTypes from "./VcaTypes"


export class LocalStorageDal {

    private static readonly onLoginStatusChanged = new LiteEvent<VcaTypes.IDriverDetails | null>();
	private static readonly onCheckStatusChanged = new LiteEvent<VcaTypes.ICheckStatusChangedEventArgs>();

	public static get loginStatusChanged() { return LocalStorageDal.onLoginStatusChanged.expose(); }

	public static get checkStatusChanged() { return LocalStorageDal.onCheckStatusChanged.expose(); }

	private static isCheckKey = (key: string): boolean => {
		return key.lastIndexOf("vca_check_", 0) === 0;
	}

	private static listAllCheckKeys = () => {
		var result: string[] = [];

		for (let i = 0; i < localStorage.length; i++) {
			const k = localStorage.key(i);
			if (k !== null && LocalStorageDal.isCheckKey(k)) {
				result.push(k);
			}
		}
		return result;
	}

	private static getCheckKeyInner(startedAt: Date) {
		return `vca_check_${startedAt.getTime()}`;
	}

	static getCheckKey(check: VcaTypes.ISavedCheck): string {
		return this.getCheckKeyInner(check.startedAt);
	}

	static saveEraObjectTypesConfig(config: VcaTypes.IEraObjectType[]) {
        const val = JSON.stringify({ config: config });
        localStorage.setItem(LocalStorageKeys.eraObjectTypes, val);
    }

    static loadEraObjectTypes(): VcaTypes.IEraObjectType[] | null {
		const str = localStorage.getItem(LocalStorageKeys.eraObjectTypes);
		if (str) {
			return JSON.parse(str).config;
		}
		return null;
	}

	static saveEraObjectsConfig(config: VcaTypes.IEraObject[]) {
        const val = JSON.stringify({ config: config });
        localStorage.setItem(LocalStorageKeys.eraObjects, val);
    }

    static loadEraObjects(): VcaTypes.IEraObject[] | null {
		const str = localStorage.getItem(LocalStorageKeys.eraObjects);
		if (str) {
			return JSON.parse(str).config;
		}
		return null;
	}

	static saveQuestionsConfig(config: VcaTypes.IQuestionsConfig) {
        const val = JSON.stringify(config);
        localStorage.setItem(LocalStorageKeys.questionsConfig, val);
    }

    static loadQuestionsConfig(): VcaTypes.IQuestionsConfig | null {
		const str = localStorage.getItem(LocalStorageKeys.questionsConfig);
		if (str) {
			try {
				return JSON.parse(str);
			} catch(ex) {
                return null;
            }
        }
		return null;
	}

	static saveInsuranceDetails(details: string) {
		localStorage.setItem(LocalStorageKeys.insuranceDetails, details);
	}

	static loadInsuranceDetails(): string | null {
		return localStorage.getItem(LocalStorageKeys.insuranceDetails);
	} 

	private static convertJsonToDateField(property: keyof VcaTypes.ISavedCheck, restoredJson: any) {
		if (restoredJson[property]) {
			restoredJson[property] = new Date(restoredJson[property] as string);
		}
	}

	static getCheck(checkKey: string): VcaTypes.ISavedCheck | null {
		if (LocalStorageDal.isCheckKey(checkKey)) {
			const str = localStorage[checkKey];
			if (str) {
				const checkList = JSON.parse(str);
				LocalStorageDal.convertJsonToDateField("startedAt", checkList);
				LocalStorageDal.convertJsonToDateField("completedAt", checkList);
				LocalStorageDal.convertJsonToDateField("synchronisedAt", checkList);
				return checkList;
			}
		}
		return null;
	}

	static saveCheck(check: VcaTypes.ISavedCheck): string {
		const key = LocalStorageDal.getCheckKey(check);
		const val = JSON.stringify(check);
		localStorage.setItem(key, val);
		LocalStorageDal.onCheckStatusChanged.trigger({ check: check, change: "save" });
		return key;
	}

	static deleteCheck(check: VcaTypes.ISavedCheck) {
		const key = LocalStorageDal.getCheckKey(check);
		localStorage.removeItem(key);
		LocalStorageDal.onCheckStatusChanged.trigger({ check: check, change: "delete" });
	}

	static listChecks = () => {
		var result: VcaTypes.ISavedCheck[] = [];
		var checkKeys = LocalStorageDal.listAllCheckKeys();
		checkKeys.forEach(k => {
			var check = LocalStorageDal.getCheck(k);
			if (check !== null) {
				result.push(check);
			}
		});
		return result;
	}

	static clearLogin = () => {
		localStorage.removeItem(LocalStorageKeys.jwtToken);
		localStorage.removeItem(LocalStorageKeys.driverDetails);
		LocalStorageDal.onLoginStatusChanged.trigger(null);
	}

	static updateToken = (jwtToken: string) => {
		localStorage.setItem(LocalStorageKeys.jwtToken, jwtToken);
	}

	static getToken = (): string | null => {
        return localStorage.getItem(LocalStorageKeys.jwtToken);
    }

	static storeLogin = (jwtToken: string, driverDetails: VcaTypes.IDriverDetails) => {
		localStorage.setItem(LocalStorageKeys.driverDetails, JSON.stringify(driverDetails));
		LocalStorageDal.updateToken(jwtToken);
		LocalStorageDal.onLoginStatusChanged.trigger(driverDetails);
	}


	static storeDistanceUnits = (units: string) => {
		localStorage.setItem(LocalStorageKeys.distanceUnits, units);
	}

	static loadDistanceUnits = (): string | null => {
		return localStorage.getItem(LocalStorageKeys.distanceUnits);
	}

	static getDriverDetails = () => {
		var result: VcaTypes.IDriverDetails | null = null;
		var value = localStorage.getItem(LocalStorageKeys.driverDetails);
		if (value) {
			result = JSON.parse(value);
		}

		return result;
	}

	static ensureAndGetDeviceId = (): string => {
		var deviceId = localStorage.getItem(LocalStorageKeys.deviceId);
		if (!deviceId) {
			deviceId = new Date().getTime().toString();
			localStorage.setItem(LocalStorageKeys.deviceId, deviceId);
		}
		return deviceId;
	}

	static getQuestionsVersions = (): VcaTypes.IQuestionsVersions => {
		var q = LocalStorageDal.loadQuestionsConfig();
        const versions: VcaTypes.IQuestionsVersions = {
            questionsVersion: null,
            profileQuestionsVersion: null
        };
		if (q) {
            versions.questionsVersion = q.questionsVersion;
			versions.profileQuestionsVersion = q.profileQuestionsVersion;
        }
        return versions;
    }

    static getProfileQuestionsVersion = (): string | null => {
        var q = LocalStorageDal.loadQuestionsConfig();
        return q && q.profileQuestionsVersion;
    }

	static listChecksAwaitingSync = () : VcaTypes.ISavedCheckForExport[] => {
	    var result: VcaTypes.ISavedCheckForExport[] = [];
	    var checkKeys = LocalStorageDal.listAllCheckKeys();
	    checkKeys.forEach(k => {
		    var check = LocalStorageDal.getCheck(k);
		    if (check && check.isComplete && !check.isSynchronised) {
			    const checkToExport = { ...check } as VcaTypes.ISavedCheckForExport;
                checkToExport.checkKey = k;
                result.push(checkToExport);
		    }
	    });
	    return result;
    }
	static setCheckSynchronised = (key: string) => {
		var check = LocalStorageDal.getCheck(key) as VcaTypes.ISavedCheck;
		check.isSynchronised = true;
		check.synchronisedAt = new Date();
		LocalStorageDal.saveCheck(check);
	}

	static getAppVersion = (): string => {
        // Needs to match the version in AppVersion.Current()
		return "0.0.10";
	}

	static saveLastSyncTimeTicks = (when : number) => {
		localStorage.setItem(LocalStorageKeys.lastSync, when.toString());
	}


    static getLastSyncTimeTicks = () : number | null => {
        var d = localStorage.getItem(LocalStorageKeys.lastSync);
        return d && parseInt(d) || null;
    }

	static clearAll = () => {
		localStorage.clear();
	}

	static clearOldCompletedChecks = (thresholdTime: Date) => {
        var checkKeys = LocalStorageDal.listAllCheckKeys();
        checkKeys.forEach(k => {
            var check = LocalStorageDal.getCheck(k);
			if (check && check.isComplete && check.synchronisedAt != null && check.synchronisedAt < thresholdTime) {
                LocalStorageDal.deleteCheck(check);
            }
        });
    }

}

class LocalStorageKeys {
    public static readonly distanceUnits = "distanceUnits";
    public static readonly jwtToken = "jwtToken";
    public static readonly driverDetails = "driverDetails";
    public static readonly questionsConfig = "questionsConfig";
    public static readonly eraObjectTypes = "eraObjectTypes";
    public static readonly eraObjects = "eraObjects";
    public static readonly deviceId = "deviceId";
    public static readonly lastSync = "lastSyncTime";
    public static readonly insuranceDetails = "insuranceDetails";
	
}
