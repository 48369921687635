import React, { Component } from 'react';


export class AddToHomeControl extends Component<any, any> {

    render() {
        return (
            <div className="card bg-dark text-light">
                <h5 className="card-header">Install App to Home screen</h5>
                <div className="card-body text-dark"><div className="card-deck">
                        <div className="card">
    
                            <div className="card-body">
                                <h5 className="card-title">iOS (iPhone / iPad)</h5>
                                <p className="card-text"></p><ol><li>Tap the browser Share button <img src="./ios_share.png" alt="iOS share icon" className="ios-share-image"/></li><li>Scroll down and select "Add to Home Screen"</li></ol>
      
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Android</h5>
                                <p className="card-text">You should be automatically prompted to install the app. If not:</p><ol><li>Tap the browser menu (3 dots)</li><li>Select "Add to homescreen"</li></ol>
                            </div>
                        </div>
  
                    </div>
    
                </div>
            </div>
        );
    }

}