import React, { Component } from "react";

interface ICheckCompleteProps {
}

interface ICheckCompleteState {
}

export class CheckComplete extends Component<ICheckCompleteProps, ICheckCompleteState> {
    constructor(props: any) {
        super(props);
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    componentDidMount = () => {
        this.scrollToTop();
    };

    render() {
      
        return (
            <div>
                <div className="alert alert-success">
                    <strong>Check completed.</strong> The check will now be sent to the office.
                </div>
                <div className="mb-2">Do you need to do another check now, e.g. for a trailer?</div>
                <div className="form-group">
                    <a className="btn btn-secondary" href="/start-check">
                        New check
                    </a>
                </div>
                <div className="form-group">
                    <a href="/">
                        Return to Home Screen
                    </a>
                </div>
            </div>
        );
    }
}
