import { LocalStorageDal } from "./LocalStorageDal";
import * as VcaTypes from "./VcaTypes";


export class Sync {

	static lastSyncAttemptTicks : number | null;
	static lastSyncAttemptResult : ISyncResponse | null;

	static initialiseTimedSync(intervalMs : number) {
		this.lastSyncAttemptTicks = null;
		const doTimedSync = () => {

			if (!this.lastSyncAttemptTicks || (new Date().getTime() - this.lastSyncAttemptTicks >intervalMs)) {
				Sync.syncNow().catch(() => {});
			}
		};

		window.setInterval(doTimedSync, 1000 * 60);
		doTimedSync();
	}

	static handleFetchErrors(response: Response) : Response {
        if (!response.ok  && response.status !== 401) {
            throw Error(response.statusText);
        }
        return response;
    }

	static trySyncNow = () => {
        Sync.syncNow().catch(() => {});
    }

	static isSyncing : boolean;

    static syncNow = async () : Promise<ISyncResponse> => {
		if (Sync.isSyncing) {
			return {status: SyncResponseStatus.AlreadySyncing}
		}
		Sync.isSyncing = true;

        const versions = LocalStorageDal.getQuestionsVersions();

		const data = {
			completedChecks: LocalStorageDal.listChecksAwaitingSync(),
			questionsVersion: versions.questionsVersion,
			profileQuestionsVersion: versions.profileQuestionsVersion,
			appVersion: LocalStorageDal.getAppVersion()
		}


        const token = LocalStorageDal.getToken();
		const jsonData = JSON.stringify(data);
		const url = "api/sync";

		Sync.lastSyncAttemptTicks = new Date().getTime();

		return fetch(url,
				{
					method: "POST",
					credentials: "same-origin",
					body: jsonData,
					headers: {
						"authorization": `bearer ${token}`,
						"Content-Type": "application/json; charset=utf-8",
						"X-Device-Uid": LocalStorageDal.ensureAndGetDeviceId()
					}
				})
			.then(Sync.handleFetchErrors)
			.then(async (response): Promise<ISyncResponse> => {

                let body: any = null;
				try {
					body = await response.json();
				} catch(ex) {

                }

				if (response.ok) {
					if (!body) {
						throw "No JSON";
					}
					const updatedToken: string = body.updatedToken;
					if (updatedToken) {
						LocalStorageDal.updateToken(updatedToken);
					}

					const receivedCheckKeys: string[] = body.receivedCheckKeys;
					const questionConfig: VcaTypes.IQuestionsConfig = body.questionConfig;
					const eraObjectTypes: VcaTypes.IEraObjectType[] = body.eraObjectTypes;
					const eraObjects: VcaTypes.IEraObject[] = body.eraObjects;
					const insuranceDetails: string = body.insuranceDetails;

					receivedCheckKeys.forEach(key => {
                        LocalStorageDal.setCheckSynchronised(key);
                    });

					if (questionConfig) {
						LocalStorageDal.saveQuestionsConfig(questionConfig);
					}
					LocalStorageDal.saveInsuranceDetails(insuranceDetails || "");
					LocalStorageDal.saveEraObjectsConfig(eraObjects);
					LocalStorageDal.saveEraObjectTypesConfig(eraObjectTypes);
					LocalStorageDal.saveLastSyncTimeTicks(new Date().getTime());
					return {status: SyncResponseStatus.Success} 

				} else if (response.status === 401) {
					return { status: SyncResponseStatus.Unauthorised }
				} else {
						return {
							status: SyncResponseStatus.Error, message: body && body.message || response.statusText
                        };
					
				}

			})
			.catch((error) => {
                return {
                    status: SyncResponseStatus.Error,
                    message: error
                }
			})
			.then(response => {
				Sync.lastSyncAttemptResult = response;

				const threshold = new Date(new Date().getTime() - 86400000); // 86400000 = ticks in 24 hours
                LocalStorageDal.clearOldCompletedChecks(threshold);
                return response;
            })
			.finally(() => {
				Sync.isSyncing = false;
			});
	}
}


export interface ISyncResponse {
	status: SyncResponseStatus;
	message?: string | null;
}

export enum SyncResponseStatus {
	Success,
	Unauthorised,
	Error,
	AlreadySyncing
}